/* @import url("../../../node_modules/jquery-ui/themes/base/all.css"); */
@import url("../../../node_modules/devextreme/dist/css/dx.common.css");
@import url("../../../node_modules/devextreme/dist/css/dx.light.css");
@import url("../../../node_modules/@devexpress/analytics-core/dist/css/dx-analytics.common.css");
@import url("../../../node_modules/@devexpress/analytics-core/dist/css/dx-analytics.light.css");
@import url("../../../node_modules/devexpress-reporting/dist/css/dx-webdocumentviewer.css");

.dx-designer-viewport {
    height: 100%;
}

.viewport {
    height: 100%
}