table.a1-responsive {						/* table */
	width: 99%;
	border-collapse: collapse;
	margin:50px auto;
	margin-bottom:40px;
}

table.a1-responsive tr:nth-of-type(odd) {
  /* zebra-striping on body rows [or cards] */
  /* background: #f0f0f0;  */
}

table.a1-responsive tr.clickable-row:hover {
	background-color: rgba(217, 212, 212, 0.178);
	cursor:pointer;
	border:1px solid rgba(0, 96, 149,0.5);
}

table.a1-responsive th {
  /* header */
  font-weight: bold;
  text-align: left;
  cursor: default;
}

table.a1-responsive th,
/* headers and body cells */
table.a1-responsive td {
	padding: 10px;
	border: 1px solid #ccc;

}


/* small size */

/*
Max width before this PARTICULAR table gets nasty
This query will take effect for any screen smaller than 760px
*/
@media
only screen and (max-width: 760px),
(min-device-width: 768px) and (max-device-width: 1024px)  {

	table.a1-responsive { 			/* table [wraps ALL cards, FYI] */
	  	width: 99%;
		table-layout: fixed;
		margin-left:2px;
		margin-right:2px;
	}

	table.a1-responsive, 			/* table no longer a table */
	table.a1-responsive thead,
	table.a1-responsive tbody,
	table.a1-responsive th,
	table.a1-responsive td,
	table.a1-responsive tr {
		display: block;
	}

	table.a1-responsive>thead>tr {	/* get rid of header from eyeballs, leave for accessibility */
		position: absolute;
		top: -9999px;
		left: -9999px;
	}

	table.a1-responsive tr { 		/* the pseudo "card" */
		border: 1px solid #ccc;
		box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
		margin-bottom:15px;
		margin-left:3px;
		margin-right:3px;
	}
	table.a1-responsive tr:hover { 		/* the pseudo "card"  when hovering over a row*/
		background-color: rgba(217, 212, 212, 0.178);
		cursor:pointer;
		border:1px solid rgba(0, 96, 149,0.5);

	}

	table.a1-responsive td { 		/* the pseudo "row" */
		border: none;
		/* border-bottom: 1px solid #eee;  */
		position: relative;
		padding-left: 50%;
		padding-bottom:15px;
		word-wrap:break-word;
		text-align: left;
	}
	table.a1-responsive td.fudge {
		text-align:right;
	}

	table.a1-responsive td:before {	/* the pseudo label [data-column] */
		position: absolute;
		top: 14px;
		left: 10px;
		width: 45%;
		padding-right: 10px;
		white-space: nowrap;
		/* Label the data */
		content: attr(data-column); /* here's the magic */
		font-weight: bold;
	}

  table.a1-responsive tr.clickable-row:hover {
    /* the pseudo "card"  when hovering over a row*/
    background-color: rgba(217, 212, 212, 0.178);
    cursor: pointer;
    border: 1px solid rgba(0, 96, 149, 0.5);

  }

  table.a1-responsive td {
    /* the pseudo "row" */
    border: none;
    /* border-bottom: 1px solid #eee;  */
    position: relative;
    padding-left: 50%;
    padding-bottom: 15px;
    word-wrap: break-word;
    text-align: left;
  }

  table.a1-responsive td.fudge {
    text-align: right;
  }

  table.a1-responsive td:before {
    /* the pseudo label [data-column] */
    position: absolute;
    top: 14px;
    left: 10px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
    /* Label the data */
    content: attr(data-column);
    /* here's the magic */
    font-weight: bold;
  }

  table.a1-responsive td.fudge:before {
    /* dont show label for fudged */
    display: none;
  }

}